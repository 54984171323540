.print-only {
    display: none;
  }
  
  .report-history-invoice {
    display: none;
  }
  
  @media print {
    .no-print {
      display: none;
    }
  
    .print-only {
      display: block;
      width: 100%;
      align-items: center;
    }
  
    td {
      border: 1px solid black;
      min-height: 150px;
      max-width: 136px;
      font-size: 14px;
      word-wrap: break-word;
    }
  
  
    table {
      margin-left: auto;
      margin-right: auto;
      margin-top: auto;
      margin-bottom: auto;
      background-color: #fff;
      width: 454px;
      height: 378px;
      border-collapse: collapse;
    }
    .box {
      display: inline-block;
      padding: 0;
      background-color: black;
      color: white;
      font-weight: bold;
      font-size: 20px;
      margin-right: 5px;
      transform: scaleY(1.5);
  }
  .box1 {
    width: 30px;
    display: inline-block;
    padding: 0;
    background-color: black;
    color: white;
    font-weight: bold;
    font-size: 10px;
    margin-right: 3px;
    transform: scaleY(2.9);
  }
  .table-tem-chon{
    margin-top: 0px;
  }
  .table-tem-chon tr {
    border: none;
    height: 20px;
    max-width: 136px;
    font-size: 14px;
    word-wrap: break-word;
  }
  .table-tem-chon td {
    border: none;
    height: 20px;
    max-width: 136px;
    font-size: 14px;
    padding:0;
    margin: 0;
    word-wrap: break-word;
  }
  }