.print-only {
  display: none;
}

.report-history-invoice {
  display: none;
}

@media print {
  .no-print {
    display: none;
  }

  .print-only {
    display: block;
    width: 100%;
    align-items: center;
  }

  body{
    zoom: 140%;
  }

  td {
    border: 1px solid black;
    min-height: 150px;
    max-width: 136px;
    font-size: 14px;
    word-wrap: break-word;
  }

  table {
    margin-left: auto;
    margin-right: auto;
    margin-top: auto;
    margin-bottom: auto;
    background-color: #fff;
    width: 378px;
    height: 283px;
    border-collapse: collapse;
  }
}
.quicker:not(.active){
  text-decoration: none;
}
.quicker.active{
  color: green;
}

.table-mh80 .ant-table-content {
  min-height: 80vh;
}

.table-mh75 .ant-table-content {
  min-height: 75vh;
}

.table-mh70 .ant-table-content {
  min-height: 70vh;
}

.table-mh40 .ant-table-content {
  min-height: 40vh;
}

.table-mh80 .ant-empty-normal {
  min-height: 60vh;
}

.table-mh75 .ant-empty-normal {
  min-height: 60vh;
}

.table-mh70 .ant-empty-normal {
  min-height: 60vh;
}

.table-mh40 .ant-empty-normal {
  min-height: 30vh;
}

.table-mh80 .ant-table-expanded-row-fixed {
  min-height: 69vh;
}

.table-mh75 .ant-table-expanded-row-fixed {
  min-height: 60vh;
}

.table-mh70 .ant-table-expanded-row-fixed {
  min-height: 65vh;
}

.table-mh60 .ant-table-expanded-row-fixed {
  min-height: 55vh;
}

.table-mh40 .ant-table-expanded-row-fixed {
  min-height: 30vh;
}

.table-mh20 .ant-table-expanded-row-fixed {
  min-height: 15vh;
}
.ant-table-wrapper .ant-table-row-expand-icon{
  color: #000;
}
.red-row td{
  background-color: red !important;
  color: #fff;
}

.ui-modal-content-scroll {
  max-height: 60vh;
  overflow-y: auto;
  border-top: 1px solid #f1f1f1;
  border-bottom: 1px solid #f1f1f1;
}

.ui-modal-content-scroll::-webkit-scrollbar {
  width: 5px; /* Adjust the width of the scrollbar */
}

.ui-modal-content-scroll::-webkit-scrollbar-track {
  background: #f1f1f1;
}

.ui-modal-content-scroll::-webkit-scrollbar-thumb {
  background: #888;
  border-radius: 4px; /* Optional: Make the scrollbar rounded */
}

.ui-modal-content-scroll::-webkit-scrollbar-thumb:hover {
  background: #555;
}

.section-pptist {
  width: 100% !important;
  height: calc(100vh - 66px) !important;
}