.edit-btn {
    color: blue;
    font-size: 18px;
    cursor: pointer;
}

.delete-btn {
    color: red;
    font-size: 18px;
    cursor: pointer;
}

.check-btn {
    color: limegreen;
    font-size: 18px;
    cursor: pointer;
}

@media print {
    @page {
        size: 100mm 75mm;
    }

    .print-template {
        width: 100mm !important;
        height: 75mm !important;
        max-width: 100mm !important;
        max-height: 75mm !important;
    }

    .print-template > table {
        width: 99mm !important;
        height: 74mm !important;
    }

    .tableTemplate tbody tr td {
        border: 1px solid black;
        /* min-height: 150px; */
        font-size: 16px !important;
        word-wrap: break-word;
        margin-left: 10px;
    }

    .tableTemplate {
        margin-left: auto;
        margin-right: auto;
        margin-top: auto;
        margin-bottom: auto;
        background-color: #fff;
        /* height: 378px; */
        border-collapse: collapse;
    }
}