.table-db .ant-table-cell {
    font-size: 1.9em !important;
    font-weight: 700 !important;
}

.bd-custom {
    font-size: 0.6em;
    line-height: 1.4em;
    font-weight: 600;
}

.table-thsx-gdlh .ant-table-cell {
    font-size: 2.5em !important;
    font-weight: 700 !important;
    height: 20vh;
}