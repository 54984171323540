.popup {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: white;
  border: 1px solid #ccc;
  padding: 20px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  z-index: 1000;
}

.popup-content {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.popup button {
  margin-top: 10px;
  padding: 10px 20px;
  background-color: #007bff;
  color: white;
  border: none;
  cursor: pointer;
}

.popup button:hover {
  background-color: #0056b3;
}

.node-list {
  margin-top: 20px;
  overflow-y: auto;
  height: 400px;
}

.node-list h2 {
  text-align: center;
}

.node-list ul {
  list-style-type: none;
  padding: 0;
}

.node-list li {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px;
  border-bottom: 1px solid #ccc;
}

.node-list .btn-active {
  padding: 5px 10px;
  background-color: #007bff;
  color: white;
  border: none;
  cursor: pointer;
}

.node-list .btn-inactive {
  padding: 5px 10px;
  background-color: #d89d2f;
  color: white;
  border: none;
  cursor: pointer;
}

.node-list button:hover {
  background-color: #0056b3;
}

.text-overlay {
  position: absolute;
  background-color: rgba(255, 255, 255, 0.8);
  border: 1px solid #ccc;
  padding: 5px;
  pointer-events: none; /* To make sure it doesn't block interactions with the viewer */
  display: none;
}

.modal-simulator .ant-modal-content {
  padding: 0 !important;
}

.modal-simulator .ant-modal-title {
  height: 55px !important;
  padding: 0 15px;
  display: flex;
  align-items: center;
  background-color: #2462a3;
  color: white;
}

.modal-simulator .ant-modal-close {
  color: white !important;
}