.date-header{
    position: absolute;
    top: 8px;
    left: 0;
    right: 0;
    margin-left: auto;
    margin-right: auto;
}

.maintenance-table .ant-table-cell{
    font-size: 10px;
    padding: 4px 4px !important;
}

.table-calendar .ant-table-cell{
    vertical-align: baseline !important;
}

.table-calendar .ant-table-tbody > tr.ant-table-row:hover > td {
    background: none !important;
}