.ant-menu-light.ant-menu-horizontal>.ant-menu-item-selected::after,
.ant-menu-light>.ant-menu.ant-menu-horizontal>.ant-menu-item-selected::after,
.ant-menu-light.ant-menu-horizontal>.ant-menu-submenu-selected::after,
.ant-menu-light>.ant-menu.ant-menu-horizontal>.ant-menu-submenu-selected::after {
    border-bottom-width: 2px;
    border-bottom-color: #ffffff;
}

.ant-menu-light.ant-menu-horizontal>.ant-menu-item-selected,
.ant-menu-light>.ant-menu.ant-menu-horizontal>.ant-menu-item-selected,
.ant-menu-light.ant-menu-horizontal>.ant-menu-submenu-selected,
.ant-menu-light>.ant-menu.ant-menu-horizontal>.ant-menu-submenu-selected {
    color: #ffffff;
    background-color: transparent;
}

.ant-menu-light .ant-menu-item:hover:not(.ant-menu-item-selected):not(.ant-menu-submenu-selected) {
    color: #fff;
}

.ant-menu-light.ant-menu-horizontal>.ant-menu-item:hover::after,
.ant-menu-light.ant-menu-horizontal>.ant-menu-item-active::after {
    border-bottom-color: #fff;
}

::-webkit-scrollbar {
    width: 10px;
    height: 10px;
}

::-webkit-scrollbar-track {
    box-shadow: inset 0 0 1px #B9b9b9;
    border-radius: 3px;
}

/* Handle */
::-webkit-scrollbar-thumb {
    background: #B9b9b9;
    border-radius: 3px;
}

.custom-row {
    height: calc(100vh - 60px);
    margin-right: 0 !important;
}

.custom-col {
    height: calc(100vh - 76px);
    overflow-y: auto;
}

.custom-col-table {
  height: calc(100vh - 76px);
}

.custom-ant-card {
    height: calc(100vh - 76px);
}

.custom-ant-card .ant-card-body {
    height: calc(100% - 56px);
    overflow-y: auto;
}

.custom-ant-card .ant-card-actions {
    text-align: center;
    position: sticky;
    bottom: 0;
    background: white;
}

.ant-divider-inner-text {
    padding-inline: 8px !important;
}

.ant-radio-group-small .ant-radio-button-wrapper {
    height: 29px;
    padding-inline: 7px;
    padding-block: 0;
    line-height: 25px;
}

/* Ghi đè kiểu lịch mặc định của Ant Design */
.ant-picker-cell {
    height: auto;
    width: auto;
    padding: 0;
  }
  
  .ant-picker-calendar .ant-picker-content th {
    height: auto;
  }
  
  .ant-picker-calendar .ant-picker-content td {
    height: auto;
    padding: 0;
  }
  
  .ant-picker-calendar .ant-picker-content .ant-picker-cell-inner {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
    width: 100%;
    padding: 2px;
    box-sizing: border-box;
  }
  
  .has-data {
    height: 200px;
    width: 200px;
    padding: 8px;
  }
  
  .no-data {
    height: 50px;
    width: 50px;
    padding: 2px;
  }
  
  .events {
    list-style: none;
    padding: 0;
    margin: 0;
  }
  
  .event {
    background: #f5f5f5;
    border-radius: 4px;
    margin-bottom: 5px;
    padding: 5px;
  }
  
  .red {
    color: red;
  }
  